import React from 'react';
import './home.scss';
import SelectBox from 'devextreme-react/select-box';
import defaultUser from '../../utils/default-user';
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";
import DataGrid, {
    Column
} from 'devextreme-react/data-grid';

const renderGridCell = (data) => {
    if (data.value) return <a target="_blank" rel="noopener" href={data.text}>
                        {data.value.substring(data.value.lastIndexOf('/') + 1).substring(0, data.value.substring(data.value.lastIndexOf('/') + 1).lastIndexOf('.'))}</a> 
};
function LinkCell(props) {
    return <a target="_blank" rel="noopener" href={props.data.dgsLink}>{props.data.linkTitle}</a>;
}
class Home extends React.Component { 
    constructor(props) {
        super(props);
        loadMessages(deMessages);
        locale(navigator.language);
        this.onValueChanged = this.onValueChanged.bind(this);
    }
    onValueChanged(e) {
        this.setState({
            value: e.value,
        });
        //notify(`The value is changed to: "${e.value}"`);
        defaultUser.client = e.value;
    }
    render() {
        const jsonUrl = 'https://dgs.it-tn.de/api/getClients.php?clientId=' + defaultUser.client + '&user=' + defaultUser.email; 
        const jsonDocs = 'https://dgs.it-tn.de/api/getDocumentLinks.php?clientId=' + defaultUser.client;
    return (
        <React.Fragment>
            <h2 className={'content-block'}>Start</h2>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                    <div id="DGSLogo">
                        <img src="https://dgs.it-tn.de/images/dgslogo.png" alt="" />
                    </div>

                    <p>Vielen Dank f&uuml;r die Nutzung unserer Kundenplattform.</p>
                    <p>
                        <span>Sie haben hier die M&ouml;glichkeit, sich &uuml;ber geplante und durchgef&uuml;hrte Audits in Ihren Standorten zu informieren.<br />
                        Weiterhin finden Sie Informationen im Zusammenhang mit durchgef&uuml;hrten Audits bei den einzelnen Standorten.<br /><br />
                        Im unteren Teil dieser Seite sind relevante Dokumentationen f&uuml;r Ihr Unternehmen verf&uuml;gbar.
                        </span>
                    </p>
                    <p>
                        <span>Melden Sie sich bei Fragen, Anregungen oder anderen Themen gerne &uuml;ber die Ihnen bekannten Kontaktwege.  </span>
                    </p>
                </div>
                <div className={'dx-card responsive-paddings'}>
                    <div className="dx-fieldset">
                        <div className="dx-fieldset-header">Mandantenauswahl</div>
                    <div className="dx-field">
                        <div className="dx-field-value">
                                <SelectBox
                                    dataSource={jsonUrl}
                                    displayExpr="msName"
                                    valueExpr="msId"
                                    width="400px"
                                    placeholder="Mandantenauswahl"
                                    onValueChanged={this.onValueChanged}
                                    defaultValue={defaultUser.client}
                                    wrapItemText={true}
                                />
                        </div>
                    </div>
                    </div>
                </div>
                <div className={'dx-card responsive-paddings'}>
                    <h5 >Dokumentationen</h5>
                    <DataGrid
                        dataSource={jsonDocs}
                        showBorders={true}
                        showColumnLines={true}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                    >
                        <Column
                            dataField={'dgsLink'}
                            caption={'Dokument'}
                            cellRender={LinkCell}
                            width={280}
                            allowHiding={false}
                             />
                        <Column
                            dataField={'linkTitle'}
                            caption={' '}
                            visible={false}
                            hidingPriority={0} />
                        <Column
                            dataField={'linkRemark'}
                            caption={'Bemerkung'}
                            allowHiding={false}
                             />
                    </DataGrid>
                </div>
            </div>
            
        </React.Fragment>
    );
    }
}
export default Home;