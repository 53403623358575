import React from 'react';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import './ObjectDetail.scss';
import AuditTab from '../AuditTab/AuditTab.js';
import AppointmentTab from '../AppointmentTab/AppointmentTab.js';
import ObjectDocLinksTab from '../ObjectDocLinksTab/ObjectDocLinksTab.js';

class ObjectDetail extends React.Component {
    constructor(props) {
        super(props);
        this.renderAuditTab = this.renderAuditTab.bind(this);
        this.renderAppointmentTab = this.renderAppointmentTab.bind(this);
        this.renderObjectDocLinksTab = this.renderObjectDocLinksTab.bind(this);
    }
    render() {
        return (
            <TabPanel>
                <Item title="Audits" render={this.renderAuditTab} />
                <Item title="Termine" render={this.renderAppointmentTab} />
                <Item title="Dokumente" render={this.renderObjectDocLinksTab} />
            </TabPanel>
        );
    }
    renderAuditTab() {
        return <AuditTab data={this.props.data.data} />;
    }
    renderAppointmentTab() {
        return <AppointmentTab data={this.props.data.data} />;
    }
    renderObjectDocLinksTab() {
        return <ObjectDocLinksTab data={this.props.data.data} />;
    }
}

export default ObjectDetail;
