export const navigation = [
    {
        text: 'Start',
        path: '/home',
        icon: 'home'
    },
    //{
    //  text: 'Example',
    //  icon: 'folder',
    //  items: [
    //    {
    //      text: 'Profile',
    //      path: '/profile'
    //    },
    //    {
    //      text: 'Tasks',
    //      path: '/tasks'
    //    }
    //  ]
    //  }, 
    {
        text: 'Audits',
        icon: 'find',
        items: [
            {
                text: 'Audits geplant',
                path: '/audit',

            },
            {
                text: 'Audits durchgef\u00fchrt',
                path: '/auditdone',
            },
            {
                text: 'Audit Anfragen',
                path: '/auditinquiry',

            }
        ]
    }, 
    {
    text: 'Standorte',
        icon: 'info',
        items: [
            {
                text: 'Allgemein',
                path: '/objects',
            },
            {
                text: 'Termin\u00fcbersicht',
                path: '/objects-appointments',
            }
        ]
    }
];