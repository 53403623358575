import React from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    Pager,
    Paging,
    FilterRow,
    Export
} from 'devextreme-react/data-grid';
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";
import defaultUser from '../../utils/default-user';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf} from 'devextreme/pdf_exporter';
import { exportDataGrid } from 'devextreme/excel_exporter';
import notify from 'devextreme/ui/notify';
//console.log(defaultUser.client)
const exportFormats = ['xlsx', 'pdf'];
const current = new Date();
const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`;
class ObjectsAppointments extends React.Component {
    constructor(props) {
        super(props);
        loadMessages(deMessages);
        locale(navigator.language);
    }
    render() {
        const jsonUrl = 'https://dgs.it-tn.de/api/getObjectsAppointments.php?clientId=' + defaultUser.client;
        return (
            <React.Fragment>
                <h2 className={'content-block'}>Termin&uuml;bersicht</h2>
                <p className={'content-block'} style={{ fontSize: 12 }}><strong>Die Daten resultieren aus den durchgef&uuml;hrten Audits und den jeweiligen Pr&uuml;fplaketten, die im Rahmen des Audits Arbeitssicherheit gepr&uuml;ft werden.</strong></p>
                <DataGrid
                    dataSource={jsonUrl}
                    showBorders={true}
                    showColumnLines={true}
                    columnAutoWidth={true}
                    noDataText={'Keine Daten'}
                    onCellPrepared={this.onCellPrepared}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    onExporting={this.onExporting}
                    onInitialized={this.onInitialized}
                    >
                    <ColumnChooser      
                        enabled={true}
                        mode="dragAndDrop"  />
                    <Paging defaultPageSize={15} />
                    <Pager showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                    />
                    <FilterRow visible={true} />
                    <Export enabled={true} formats={exportFormats} />
                    <Column
                        dataField={'rowId'}
                        width={90}
                        caption={'#'}
                        hidingPriority={0} />
                    <Column
                        dataField={'company'}
                        allowHiding={false}
                        allowReordering={false}
                        caption={'Standort'}
                        hidingPriority={11} />
                    <Column
                        dataField={'os_as'}
                        caption={'Arbeitssicherheit'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        alignment={'center'}
                        hidingPriority={10} />
                    <Column
                        dataField={'os_of'}
                        caption={'Ortsfest'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        alignment={'center'}
                        hidingPriority={9} />
                    <Column
                        dataField={'os_ov'}
                        caption={'Ortsver\u00e4nderlich'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        alignment={'center'}
                        hidingPriority={8} />
                    <Column
                        dataField={'os_sl'}
                        caption={'Sicherheitsbeleuchtung'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        alignment={'center'}
                        hidingPriority={7} />
                    <Column
                        dataField={'os_fc'}
                        caption={'FI Test'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        alignment={'center'}
                        hidingPriority={6} />
                    <Column
                        dataField={'os_24'}
                        caption={'Brandschutzt\u00fcren'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        alignment={'center'}
                        hidingPriority={5} />
                    <Column
                        dataField={'os_25'}
                        caption={'Kraftbet\u00e4tigte T\u00fcren und Tore'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        alignment={'center'}
                        hidingPriority={4} />
                    <Column
                        dataField={'os_31'}
                        caption={'Rauch- und W\u00e4rmeabzugsanlage'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        alignment={'center'}
                        hidingPriority={3} />
                    <Column
                        dataField={'os_35'}
                        caption={'Feuerl\u00f6scher'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        alignment={'center'}
                        hidingPriority={2} />
                    <Column
                        dataField={'os_36'}
                        caption={'Wandhydranten'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        alignment={'center'}
                        hidingPriority={1} />
                </DataGrid>
            </React.Fragment>
        );
    }
    onCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.dataField === "company") {
            const active = e.data.os_aktiv;
            e.cellElement.style.color = active === "n" ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_as") {
            const d1 = Date.parse(e.data.os_as);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_of") {
            const d1 = Date.parse(e.data.os_of);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_ov") {
            const d1 = Date.parse(e.data.os_ov);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_sl") {
            const d1 = Date.parse(e.data.os_sl);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_fc") {
            const d1 = Date.parse(e.data.os_fc);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_24") {
            const d1 = Date.parse(e.data.os_24);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_25") {
            const d1 = Date.parse(e.data.os_25);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_31") {
            const d1 = Date.parse(e.data.os_31);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_35") {
            const d1 = Date.parse(e.data.os_35);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_36") {
            const d1 = Date.parse(e.data.os_36);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
    };
    onExporting(e) {
        if (e.format === 'xlsx') {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Termine');
            exportDataGrid({
                component: e.component,
                worksheet,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Termine.xlsx');
                });
            });
        }
        if (e.format === 'pdf') {
            const doc = new jsPDF('l');
            exportDataGridToPdf({
                jsPDFDocument: doc,
                component: e.component
            }).then(() => {
                doc.save('Termine.pdf');
            });
        };
    };
    onInitialized(e) {
        if (defaultUser.userGroup !== 'dgs') {
            e.component.columnOption('os_as', 'visible', false);
            e.component.columnOption('os_as', 'showInColumnChooser', false);
            e.component.columnOption('os_of', 'visible', false);
            e.component.columnOption('os_of', 'showInColumnChooser', false);
            e.component.columnOption('os_ov', 'visible', false);
            e.component.columnOption('os_ov', 'showInColumnChooser', false);
            e.component.columnOption('os_sl', 'visible', false);
            e.component.columnOption('os_sl', 'showInColumnChooser', false);
            e.component.columnOption('os_fc', 'visible', false);
            e.component.columnOption('os_fc', 'showInColumnChooser', false);
        }
    };
}
export default ObjectsAppointments;