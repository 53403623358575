import React from 'react';
import './AuditTab.scss';
import DataGrid, {
    Column,
    MasterDetail
} from 'devextreme-react/data-grid';
import auditjobs from '../auditjobs/auditjobs.js';

class AuditTab extends React.Component {
    render() {
        const { osId } = this.props.data;
        const jsonUrl = 'https://dgs.it-tn.de/api/getObjectAudits.php?objectId=' + osId;
        return (
            <React.Fragment>
                <DataGrid
                    dataSource={jsonUrl}
                    showBorders={true}
                    showColumnLines={true}
                    columnAutoWidth={true}
                    noDataText={'Keine Daten'}
                >
                    <Column
                        dataField={'id'}
                        width={90}
                        caption={'ID'}
                        hidingPriority={1} />
                    <Column
                        dataField={'auditDate'}
                        caption={'Auditdatum'}
                        hidingPriority={4} />
                    <Column
                        dataField={'auditor'}
                        caption={'Pr\u00fcfer'}
                        hidingPriority={3} />
                    <Column
                        dataField={'responsibleName'}
                        caption={'Ansprechpartner'}
                        hidingPriority={2} />
                    <MasterDetail
                        enabled={true}
                        component={auditjobs}
                    />
                </DataGrid>
            </React.Fragment>
        );
    }
}
export default AuditTab;