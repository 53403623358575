import React from 'react';
//import 'devextreme/data/odata/store';
//import DataSource from 'devextreme/data/data_source';
import DataGrid, {
    Column,
    MasterDetail,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Editing
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { confirm } from 'devextreme/ui/dialog';
import defaultUser from '../../utils/default-user';
import auditinquiryobjects from '../auditinquiryobjects/auditinquiryobjects.js';
import 'whatwg-fetch';

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}
function updateRow(e) {
    const isCanceled = new Promise((resolve, reject) => {
        const promptPromise = confirm("Anfrage schlie\u00dfen?", "Best\u00e4tigung");
        promptPromise.then((dialogResult) => {
            if (dialogResult) {
                resolve(false);
            } else {
                return resolve(true);
            }
        });
    });
    e.cancel = isCanceled;
}

//console.log(defaultUser.client)

class AuditInquiry extends React.Component {
    constructor(props) {
        super(props);
        this.allowUpdating = this.allowUpdating.bind(this);
    }
    isClosed(status) {
        return  status && status > 2;
    }

    allowUpdating(e) {
        return !this.isClosed(e.row.data.ai_status);
    }
    render() {
        const store = new CustomStore({
            key: 'ai_id',
            load() {
                return fetch('https://dgs.316.it-tn.de/api/getAuditInquiry.php?clientId=' + defaultUser.client)
                    .then((response) => response.json())
                    .catch(() => { throw new Error('Data Loading Error');});
            },
            update: (key, values) =>  {
                   return fetch(`https://dgs.316.it-tn.de/api/AuditInquiry/service.php?ID=${key}` , {
                    method: "OPTIONS",
                    body:  JSON.stringify(values)
                })
                .then(handleErrors);
            },
            remove: (key) => {
                 return fetch(`https://dgs.it-tn.de/api/AuditInquiry/service.php?ID=${key}`, {
                    method: "DELETE"
                })
                .then(handleErrors);
            }
    });
    const inquiryStatus = new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load() {
                return fetch('https://dgs.316.it-tn.de/api/getInquiryStatus.php')
                    .then((response) => response.json())
                    .catch(() => { throw new Error('Data Loading Error');});
            }
    });
        return (
            <React.Fragment>
                <h2 className={'content-block'}>Audit Anfragen</h2>
                <DataGrid
                    dataSource={store}
                    showBorders={true}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    hoverStateEnabled={true}
                    focusedRowEnabled={true}
                    defaultFocusedRowIndex={0}
                    //onRowValidating={this.onRowValidating}
                    remoteOperations={false}
                    onRowUpdating={updateRow}
                >
                    <Paging defaultPageSize={15} />
                    <Pager showPageSizeSelector={true} showInfo={true} />
                    <FilterRow visible={true} />
                    <Editing
                        mode="row"
                        allowUpdating={this.allowUpdating}
                    />
                    <Column
                        dataField={'ai_id'}
                        allowEditing={false}
                        width={90}
                        caption={'Id'}
                        hidingPriority={3} />
                    <Column
                        dataField={'ai_date_from'}
                        dataType={"datetime"}
                        format={'EEEE, dd.MM.yyyy HH:mm'}
                        allowEditing={false}
                        caption={'Datum'}
                        hidingPriority={2} />
                    <Column
                        dataField={'ai_status'}
                        dataType={"number"}
                        caption={'Status'}
                        hidingPriority={1}> 
                        <Lookup dataSource={inquiryStatus} 
                                valueExpr="id" 
                                displayExpr="text"
                                applyValueMode="useButtons"/>
                    </Column>
                    <Column
                        dataField={'ai_name'}
                        allowEditing={false}
                        caption={'Pr\u00fcfer'}
                        hidingPriority={0} />
                    <MasterDetail
                        enabled={true}
                        component={auditinquiryobjects} />
                </DataGrid>
            </React.Fragment>
        );
    }
}
export default AuditInquiry;
