import React from 'react';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow
} from 'devextreme-react/data-grid';
import defaultUser from '../../utils/default-user';
//console.log(defaultUser.client)
//const jsonUrl = 'https://audit-pro.net/api/getAuditsPlanned.php?clientId=' + defaultUser.client;

class Audit extends React.Component {
    render() {
        const jsonUrl = 'https://dgs.it-tn.de/api/getAuditsPlanned.php?clientId=' + defaultUser.client;
        return (
            <><React.Fragment>
                <h2 className={'content-block'}>Audits geplant</h2>
                <p className={'content-block'} style={{ fontSize: 12 }}><strong>Angezeigt sind die geplanten Audits 
                    sowie die durchgef&uuml;hrten Audits der laufenden Woche.</strong></p>
                <DataGrid
                    dataSource={jsonUrl}
                    //keyExpr="rowId"
                    showBorders={true}
                    showColumnLines={true}
                    //focusedRowEnabled={true}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    noDataText={'Keine Daten'}
                >
                    <Paging defaultPageSize={15} />
                    <Pager showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                        //infoText={'Seite {0} von {1} ({2} Zeilen)'}
                    />
                    <FilterRow visible={true} />
                    <Column
                        dataField={'rowId'}
                        width={90}
                        caption={'#'}
                        hidingPriority={1} />
                    <Column
                        dataField={'company'}
                        caption={'Standort'}
                        hidingPriority={7} />
                    <Column
                        dataField={'street'}
                        caption={'Stra\u00dfe'}
                        hidingPriority={4} />
                    <Column
                        dataField={'zipcode'}
                        caption={'PLZ'}
                        hidingPriority={2} />
                    <Column
                        dataField={'city'}
                        caption={'Ort'}
                        hidingPriority={3} />
                    <Column
                        dataField={'objectJobs'}
                        caption={'Audits'}
                        hidingPriority={6} />
                    <Column
                        dataField={'displayDate'}
                        caption={'Plandatum'}
                        hidingPriority={5} />
                    <Column
                        dataField={'dateDone'}
                        caption={'Auditdatum'}
                        hidingPriority={7} />
                </DataGrid>
            </React.Fragment>
                <React.Fragment>
                    <p className={'content-block'} style={{ fontSize: 10 }}><strong>Legende</strong><br /> AS = Arbeitssicherheit<br />OF = Ortsfeste elektrische Anlagen<br />OV = Ortsver&auml;nderliche elektrische Betriebsmittel<br />SB = Sicherheitsbeleuchtung</p>
                </React.Fragment></>
        );
    }
}
export default Audit;
