import React from 'react';
import DataGrid, {
    Column,
    MasterDetail,
    Pager,
    Paging,
    FilterRow
} from 'devextreme-react/data-grid';
import defaultUser from '../../utils/default-user';
import ObjectDetail from '../ObjectDetail/ObjectDetail.js';
//console.log(defaultUser.client)

class Objects extends React.Component {
    render() {
        const jsonUrl = 'https://dgs.it-tn.de/api/getObjects.php?clientId=' + defaultUser.client;
        return (
            <><React.Fragment>
                <h2 className={'content-block'}>Standorte</h2>
                <DataGrid
                    dataSource={jsonUrl}
                    showBorders={true}
                    showColumnLines={true}
                    //defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    noDataText={'Keine Daten'}
                    onCellPrepared={this.onCellPrepared}
                >
                    <Paging defaultPageSize={15} />
                    <Pager showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                        //infoText={'Seite {0} von {1} ({2} Zeilen)'}
                    />
                    <FilterRow visible={true} />
                    <Column
                        dataField={'rowId'}
                        width={90}
                        caption={'#'}
                        hidingPriority={1} />
                    <Column
                        dataField={'company'}
                        caption={'Standort'}
                        hidingPriority={7} />
                    <Column
                        dataField={'street'}
                        caption={'Stra\u00dfe'}
                        hidingPriority={4} />
                    <Column
                        dataField={'zipcode'}
                        caption={'PLZ'}
                        hidingPriority={2} />
                    <Column
                        dataField={'city'}
                        caption={'Ort'}
                        hidingPriority={3} />
                    <MasterDetail
                        enabled={true}
                        component={ObjectDetail}
                    />
                </DataGrid>
            </React.Fragment></>
        );
    }
    onCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.dataField === "company") {
            const active = e.data.os_aktiv;
            e.cellElement.style.color = active === "n" ? "red" : "undefined";
        }
    }
}
export default Objects;
