import React from 'react';
import './ObjectDocLinksTab.scss';
import DataGrid, {
    Column
} from 'devextreme-react/data-grid';
function LinkCell(props) {
    return <a target="_blank" rel="noopener" href={props.data.dgsLink}>{props.data.linkTitle}</a>;
}
class ObjectDocLinksTab extends React.Component {
    render() {
        const { osId } = this.props.data;
        const jsonUrl = 'https://dgs.it-tn.de/api/getObjectDocLinks.php?objectId=' + osId;
        return (
            <React.Fragment>
                <DataGrid
                    dataSource={jsonUrl}
                    showBorders={true}
                    showColumnLines={true}
                    columnAutoWidth={true}
                    noDataText={'Keine Daten'}
                >
                    <Column
                        dataField={'dgsLink'}
                        width={280}
                        caption={'Dokument'}
                        cellRender={LinkCell}
                        hidingPriority={1} />
                    <Column
                        dataField={'linkTitle'}
                        caption={' '}
                        visible={false}
                        hidingPriority={2} />
                    <Column
                        dataField={'linkRemark'}
                        caption={'Bemerkung'}
                        hidingPriority={3} />
                </DataGrid>
            </React.Fragment>
        );
    }
}
export default ObjectDocLinksTab;

