import { HomePage, TasksPage, ProfilePage, AuditPage, AuditdonePage, AuditjobsPage, ObjectsPage, ObjectDetailPage, AuditTabPage, AppointmentTabPage, ObjectsAppointmentsPage, AuditinquiryPage, AuditinquiryobjectsPage, ObjectDocLinksTabPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/tasks',
        element: TasksPage
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/home',
        element: HomePage
    }, 
  {
    path: '/audit',
    element: AuditPage
  }, 
  {
    path: '/auditdone',
    element: AuditdonePage
  }, 
  {
    path: '/auditjobs',
    element: AuditjobsPage
  }, 
  {
    path: '/objects',
    element: ObjectsPage
  }, 
  {
    path: '/object-detail',
    element: ObjectDetailPage
  }, 
  {
    path: '/audit-tab',
    element: AuditTabPage
  }, 
  {
    path: '/appointment-tab',
    element: AppointmentTabPage
  }, 
  {
    path: '/objects-appointments',
    element: ObjectsAppointmentsPage
  }, 
  {
    path: '/auditinquiry',
    element: AuditinquiryPage
  }, 
  {
    path: '/auditinquiryobjects',
    element: AuditinquiryobjectsPage
  }, 
  {
    path: '/object-doc-links-tab',
    element: ObjectDocLinksTabPage
  }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
