import React from 'react';
import DataGrid, {
    Column,
    ColumnChooser
} from 'devextreme-react/data-grid';

const current = new Date();
const date = `${current.getMonth() + 1}.${current.getDate()}.${current.getFullYear()}`;

class AppointmentTab extends React.Component {
    render() {
        const { osId } = this.props.data;
        const jsonUrl = 'https://dgs.it-tn.de/api/getObjectAppointments.php?objectId=' + osId;
        return (
            <React.Fragment>
                <p className={'content-block'} style={{ fontSize: 12 }}><strong>Die Daten resultieren aus den durchgef&uuml;hrten Audits und den jeweiligen Pr&uuml;fplaketten, die im Rahmen des Audits Arbeitssicherheit gepr&uuml;ft werden.</strong></p>
                <DataGrid
                    dataSource={jsonUrl}
                    showBorders={true}
                    showColumnLines={true}
                    columnAutoWidth={true}
                    noDataText={'Keine Daten'}
                    onCellPrepared={this.onCellPrepared}
                    allowColumnReordering={true}>
                >
                <ColumnChooser      
                        enabled={true}
                        mode="dragAndDrop"  />
                    <Column
                        dataField={'os_as'}
                        caption={'Arbeitssicherheit'}
                        alignment={'center'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        hidingPriority={10} />
                    <Column
                        dataField={'os_of'}
                        caption={'Ortsfest'}
                        alignment={'center'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        hidingPriority={9} />
                    <Column
                        dataField={'os_ov'}
                        caption={'Ortsver\u00e4nderlich'}
                        alignment={'center'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        hidingPriority={8} />
                    <Column
                        dataField={'os_sl'}
                        caption={'Sicherheitsbeleuchtung'}
                        alignment={'center'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        hidingPriority={7} />
                    <Column
                        dataField={'os_fc'}
                        caption={'FI Test'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        alignment={'center'}
                        hidingPriority={6} />
                    <Column
                        dataField={'os_24'}
                        caption={'Brandschutzt\u00fcren'}
                        alignment={'center'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        hidingPriority={5} />
                    <Column
                        dataField={'os_25'}
                        caption={'Kraftbet\u00e4tigte T\u00fcren und Tore'}
                        alignment={'center'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        hidingPriority={4} />
                    <Column
                        dataField={'os_31'}
                        caption={'Rauch- und W\u00e4rmeabzugsanlage'}
                        alignment={'center'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        hidingPriority={3} />
                    <Column
                        dataField={'os_35'}
                        caption={'Feuerl\u00f6scher'}
                        alignment={'center'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        hidingPriority={2} />
                    <Column
                        dataField={'os_36'}
                        caption={'Wandhydranten'}
                        alignment={'center'}
                        dataType={'date'}
                        format={'dd.MM.yyyy'}
                        hidingPriority={1} />
                </DataGrid>
            </React.Fragment>
        );
    }
    onCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.dataField === "os_as") {
            const d1 = Date.parse(e.data.os_as);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_of") {
            const d1 = Date.parse(e.data.os_of);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_ov") {
            const d1 = Date.parse(e.data.os_ov);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_sl") {
            const d1 = Date.parse(e.data.os_sl);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_fc") {
            const d1 = Date.parse(e.data.os_fc);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_24") {
            const d1 = Date.parse(e.data.os_24);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_25") {
            const d1 = Date.parse(e.data.os_25);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_31") {
            const d1 = Date.parse(e.data.os_31);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_35") {
            const d1 = Date.parse(e.data.os_35);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
        if (e.rowType === "data" && e.column.dataField === "os_36") {
            const d1 = Date.parse(e.data.os_36);
            const d2 = Date.parse(date);
            e.cellElement.style.color = d1 <= d2 ? "red" : "undefined";
        }
    }
}
export default AppointmentTab;