import defaultUser from '../utils/default-user';

export async function signIn(email, password) {
  try {
    // Send request
      const json = '{"email":"' + email + '","password":"' + password + '"}'
      const response =
          await fetch("https://dgs.316.it-tn.de/api/logon.php?action=logIn", {
              method: 'POST',
              body: json
          })
      const result = JSON.parse(JSON.stringify(await response.json())) //JSON.parse('{"email":"aa@example.de","password":1}');
      const user = result.user
      const logon = result.logon
      const client = result.clientId
      const userGroup = result.userGroup
      if (logon === 1) {
          defaultUser.email = email
          defaultUser.user = user
          defaultUser.client = client
          defaultUser.userGroup = userGroup
      }
      else {
          return {
              isOk: false,
              message: "Anmeldung fehlerhaft"
          };
      }
    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false,
        message: "Anmeldung fehlerhaft"
    };
  }
}
export async function signOut() {
    try {
        // Send request
        const json = '{"email":"' + defaultUser.email  + '"}'
        const response =
            await fetch("https://dgs.it-tn.de/api/logon.php?action=logOut", {
                method: 'POST',
                body: json
            })
        return {
            isOk: true
        };
    }
    catch {
        return {
            isOk: false
        };
    }
}

export async function getUser() {
  try {
    // Send request

    return {
      isOk: false
      //data: defaultUser
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
